<template>
  <!-- 分组管理页面 -->
  <div>
    <h2>分组管理页面</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
 
<style lang="less" scoped>
</style>